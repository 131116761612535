export const ROLE_PATH = {
	user: '/user',
	admin: '/admin',
	hr: '/hr',
	superAdmin: '/superAdmin',
	manager: '/manager',
	teamleader: '/teamleader',
	executive: '/executive',
	telecaller: '/telecaller',
};

export const ROLE = {
	admin: 'admin',
	user: 'user',
	hr: 'hr',
	manager: 'manager',
	teamleader: 'teamleader',
	telecaller: 'telecaller',
	executive: 'executive',
};
