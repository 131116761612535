const keys = {
	nodeENV: process.env.REACT_APP_NODE_ENV,
	baseLocalUrl: process.env.REACT_APP_WEEAM_LOCAL_API,
	baseLiveUrl: process.env.REACT_APP_WEEAM_LIVE_API,
	server2: process.env.REACT_APP_SERVER2,
	wssSocketUrl: process.env.REACT_APP_SOCKET_WSS_API,
	socketUrl: process.env.REACT_APP_SOCKET_API,
	version: process.env.REACT_APP_RELEASE_VERSION,
};

export default keys;
