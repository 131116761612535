import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify"; // Ensure you have the react-toastify library
import keys from "config/keys";

const useFetchAnnouncements = (userId, currentPage, itemsPerPage) => {
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);

	const getAnnouncements = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(
				`${keys.socketUrl}/posted_announcements?user_id=${userId}&page=${currentPage}&size=${itemsPerPage}`,
				{ maxRedirects: 0 } // Prevent auto-following redirects
			);

			if (data?.total_announcements > 0) {
				// setList(data.announcements);
				// Append new announcements to the existing list
				setList((prevList) => [...prevList, ...data.announcements]);
				setTotalPages(data.total_pages);
			} else {
				setList([]); // Clear list if no announcements
				setTotalPages(0);
			}
		} catch (error) {
			if (error.response?.status === 307) {
				console.log("Redirected to:", error.response.headers.location);
			} else {
				toast.error("Failed to fetch announcements.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (userId) {
			getAnnouncements();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, currentPage]);

	return { list, loading, totalPages };
};

export default useFetchAnnouncements;
